// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// vanilla JS
// init with element

$(document).on("turbolinks:load", function () {
  $.rails.refreshCSRFTokens();

  $("ul#products").infiniteScroll({
    // options
    path: ".next a",
    append: ".products_list_item",
    history: false,
  });

  Spree.ready(function ($) {
    $(".doption-trigger").on("change", function () {
      let dPrice = parseFloat($("#base_price").val());
      $(".doption-trigger").each(function () {
        console.log(
          "surcharge: " + $(this).find(":selected").data("surcharge")
        );
        dPrice += parseFloat($(this).find(":selected").data("surcharge"));
      });
      $(".price.selling").text("$" + dPrice.toFixed(2));
    });
  });
});

import "stylesheets/application";

SolidusStripe.Elements.prototype.baseStyle = function () {
  return {
    base: {
      iconColor: "#c4f0ff",
      border: "1px solid #d9d9db",
      fontWeight: 500,
      fontFamily: "'Proxima-Nova', Gotham, Helvetica, Arial",
      padding: "10px",
      fontSize: "15px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#999",
      },
    },
    invalid: {
      iconColor: "#e45353",
      color: "#e45353",
    },
  };
};

Spree.ready(function ($) {
  // Products
  Spree.addImageHandlers = function () {
    var thumbnails = $("#product-images ul.thumbnails");
    $("#main-image").data("selectedThumb", $("#main-image img").attr("src"));
    if (!thumbnails.find("li.selected").length) {
      thumbnails.find("li").eq(0).addClass("selected");
    }
    thumbnails.find("a").on("click", function (event) {
      $("#main-image").data(
        "selectedThumb",
        $(event.currentTarget).attr("href")
      );
      $("#main-image").data(
        "selectedThumbId",
        $(event.currentTarget).parent().attr("id")
      );
      thumbnails.find("li").removeClass("selected");
      $(event.currentTarget).parent("li").addClass("selected");
      return false;
    });
    thumbnails.find("li").on("mouseenter", function (event) {
      $("#main-image img").attr(
        "src",
        $(event.currentTarget).find("a").attr("href")
      );
    });
    thumbnails.find("li").on("mouseleave", function (event) {
      $("#main-image img").attr("src", $("#main-image").data("selectedThumb"));
    });
  };

  Spree.showVariantImages = function (variantId) {
    $("li.vtmb").hide();
    $("li.tmb-" + variantId).show();
    var currentThumb = $("#" + $("#main-image").data("selectedThumbId"));
    if (!currentThumb.hasClass("vtmb-" + variantId)) {
      var thumb = $($("#product-images ul.thumbnails li:visible.vtmb").eq(0));
      if (!(thumb.length > 0)) {
        thumb = $($("#product-images ul.thumbnails li:visible").eq(0));
      }
      var newImg = thumb.find("a").attr("href");
      $("#product-images ul.thumbnails li").removeClass("selected");
      thumb.addClass("selected");
      $("#main-image img").attr("src", newImg);
      $("#main-image").data("selectedThumb", newImg);
      $("#main-image").data("selectedThumbId", thumb.attr("id"));
    }
  };

  Spree.updateVariantPrice = function (variant) {
    var variantPrice = variant.data("price");
    var variantOnSale = variant.data("on-sale");
    var variantOriginalPrice = variant.data("original-price");
    if (variantPrice) {
      $(".price.selling").text(variantPrice);
    }
    if (variantOnSale) {
      // console.log("on sale");
      $(".on-sale-price").text(variantOriginalPrice);
    } else {
      $(".on-sale-price").text("");
    }
  };

  var select = $("#product-variants select");
  var options = select.children("option");
  if (options.length > 0) {
    var selectedOption = select.find(":selected");
    Spree.showVariantImages(selectedOption.attr("value"));
    Spree.updateVariantPrice(selectedOption);
  }

  Spree.addImageHandlers();

  select.change(function (event) {
    Spree.showVariantImages($(this).val());
    Spree.updateVariantPrice($(this).find(":selected"));
  });
});
